@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.header {
  text-align: left;
  height: 8%;
  background-color: #12a9e1;
  z-index: 1000;
  position: fixed;
  width: 100%;
}

.App {
  padding-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center; 
  font-size: calc(10px + 2vmin);
  color: #12a9e1;
  font-family: 'Inter', sans-serif;
  text-align: center;
  overflow: auto;
}

.info-box {
  width: 90%;
  border-radius: 5px;
  margin-bottom: 1%;
  padding: 1%;
}

.circle-step-number {
  display: inline-block;
  margin-right: 12px;
  width: 28px;
  height: 28px;
  background-color: #12a9e1;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
}

.circle-step-number span {
  display: inline-block;
  vertical-align: middle;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}